<template>
    <div class="frame">
        <div class="bg"></div>
        <div class="content">
            <div class="c_left">
                <div class="cl_top">
                    <span>{{ nav.name }}</span>
                    <span></span>
                </div>
                <div class="cl_bottom">
                    <div class="block" v-for="item in nav.children" :key="item.id + 1" @click="click(item)">{{ item.name
                    }}
                    </div>
                </div>
            </div>
            <div class="f_right">
                <div class="cr_title">
                    <span>您当前的位置：</span>
                    <el-breadcrumb separator=">">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ nav.name }}</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="cname != ''">{{ cname }}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ data.title }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="f_title" v-if="showContentOnly == 0">{{ data.title }}</div>
                <div class="f_desc" v-if="showContentOnly == 0">
                    <div class="fd_left">
                        分类：{{ data.categoryName }}
                    </div>
                    <div class="fd_left">
                        作者：{{ data.author }}
                    </div>
                    <div class="fd_left">
                        来源：{{ data.origin }}
                    </div>
                    <div class="fd_left">
                        发布时间：{{ data.createTime }}
                    </div>
                </div>
                <div class="content2" v-html="data.contents"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: '',
            id: '',
            cid: '',
            nav: {},
            cname: '',
            showContentOnly: 0,
            seodata: {}
        }
    },
    metaInfo() {
        return {
            title: this.seodata.title,
            meta: [
                { name: "keywords", content: this.seodata.keywords },
                { name: "description", content: this.seodata.description },
            ]
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.showContentOnly = this.$route.query.showContentOnly != undefined ? this.$route.query.showContentOnly : 0
        this.cname = ''
        this.$axios.get(`/api/business/homePageInfo/${this.id}`).then(res => {
            this.data = res.data.data
        })
        this.$axios.get(`/api/business/homePageInfo/getInfoSEO/${this.id}`).then(res => {
            this.seodata = res.data.data
        })
    },

    watch: {
        nav(nel, old) {
            for (let i in nel.children) {
                if (nel.children[i].id == this.cid) {
                    this.cname = nel.children[i].name
                }
            }
        },
        data(nel, old) {
            this.cid = nel.categoryId
            this.getMenu()
        }
    },
    methods: {
        click(e) {
            if (e.url) {
                if (e.openType == 1) {
                    window.open(e.url)
                } else {
                    window.location.replace(e.url)
                }
            } else {
                this.$router.push('/newList/' + e.id)
            }
        },

        getMenu() {
            this.$axios.get('/api/business/homePageInfo/getTop').then(res => {
                let data = res.data.data.navigationBar
                for (let i in data) {
                    if (data[i].id == this.cid) {
                        this.nav = data[i]
                        return
                    } else {
                        for (let j in data[i].children) {
                            if (data[i].children[j].id == this.cid) {
                                this.nav = data[i]
                                return
                            }
                        }
                    }
                }
            })
        }
    }
}
</script>


<style lang="less" scoped>
.frame {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 424px;
        background: url('@/assets/bg1.png') no-repeat;
        background-size: 100% 100%;
    }

    .content {
        width: 1200px;
        height: auto;
        margin: -240px auto 0;
        background: white;
        display: flex;
        flex-direction: row;

        .c_left {
            width: 217px;
            height: auto;
            background: white;
            margin-top: -50px;

            .cl_top {
                width: 217px;
                height: 123px;
                background: url('@/assets/icon.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;

                span {
                    display: inline-block;
                    margin-left: 26px;

                    &:nth-child(1) {
                        font-size: 23px;
                        color: white;
                        margin-top: 20px;

                    }

                    &:nth-child(2) {
                        font-size: 14px;
                        color: #659DE4;
                        margin-top: 5px;
                    }
                }
            }

            .cl_bottom {
                width: 100%;
                display: flex;
                height: auto;
                flex-direction: column;
                justify-content: center;
                margin-top: 5px;
                border-right: 1px solid #F5F5F5;

                :hover {
                    background: #3687f1 !important;
                    color: white !important;
                }

                div {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    cursor: pointer;
                    border-bottom: 1px solid #F5F5F5;

                    &:last-child {
                        border: none !important;
                    }
                }
            }
        }

        .f_right {
            width: 100%;
            overflow: hidden;

            .cr_title {
                width: 100%;
                height: 60px;
                line-height: 60px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 20px;
                border-bottom: 1px solid #DDDDDD;
                padding-right: 20px;
                box-sizing: border-box;
                font-size: 14px;
                color: #606266;

            }

            .f_title {
                width: 90%;
                font-size: 28px;
                line-height: 40px;
                margin: 35px auto 20px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .f_desc {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #878787;
                line-height: 40px;
                border-bottom: 1px dashed #E2E2E2;

                .fd_left {
                    margin-right: 10px;
                }
            }

            .content2 {
                padding: 20px 40px 50px;
                box-sizing: border-box;
                font-size: 14px;
                color: #878787;
                line-height: 25px;
                overflow: hidden;
                image{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>