<template>
    <div class="frame">
        <div class="bg">
            <div class="swiper">
                <el-carousel height="348px">
                    <el-carousel-item
                        v-for="item in topData.carouselInfo"
                        :key="item.id"
                    >
                        <el-image
                            @click="swiperImg(item.url)"
                            style="
                                width: 1200px;
                                height: 348px;
                                cursor: pointer;
                            "
                            :src="item.img"
                        ></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="headlines">
                <div class="h_img">
                    <img src="@/assets/icon8.png" alt="" />
                </div>
                <div class="h_right">
                    <div class="ht_title" @click="clickList(topInformation)">
                        {{ topInformation.title || '' }}
                    </div>
                    <el-row class="el-row">
                        <el-col :span="7">
                            <span @click="clickList(hotinfoList[0])">{{
                                hotinfoList[0].title
                            }}</span>
                        </el-col>
                        <el-col :span="7" @click="clickList(hotinfoList[1])">
                            <span @click="clickList(hotinfoList[1])">{{
                                hotinfoList[1].title
                            }}</span>
                        </el-col>
                        <el-col :span="7" @click="clickList(hotinfoList[2])">
                            <span @click="clickList(hotinfoList[2])">{{
                                hotinfoList[2].title
                            }}</span>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div class="content">
                <!-- 咨询轮播 -->
                <div class="c_swiper">
                    <el-carousel height="290px">
                        <el-carousel-item
                            v-for="item in contentData.rotationList"
                            :key="item.id"
                        >
                            <img
                                :src="
                                    item.mainImg
                                        ? item.mainImg + '&width=600'
                                        : ''
                                "
                                alt=""
                                @click="clickList(item)"
                                loading="lazy"
                            />
                            <div class="cs_title">{{ item.title }}</div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="c_ul">
                    <div class="cu_header">
                        <div @click="click(1)" :class="{ hover: index1 == 1 }">
                            <img
                                style="width: 24px; height: auto"
                                src="@/assets/icon19.png"
                                alt=""
                                v-if="index1 != 1"
                            />
                            <img
                                style="width: 24px; height: auto"
                                src="@/assets/icon20.png"
                                alt=""
                                v-else
                            />
                            环卫动态
                        </div>
                        <div @click="click(2)" :class="{ hover: index1 == 2 }">
                            <img
                                style="width: 18px; height: auto"
                                src="@/assets/icon12.png"
                                v-if="index1 != 2"
                            />
                            <img
                                style="width: 18px; height: auto"
                                src="@/assets/icon11.png"
                                v-else
                            />
                            领导关怀
                        </div>
                        <div @click="click(3)" :class="{ hover: index1 == 3 }">
                            <img
                                style="width: 27px; height: auto"
                                src="@/assets/icon14.png"
                                v-if="index1 != 3"
                            />
                            <img
                                style="width: 27px; height: auto"
                                src="@/assets/icon13.png"
                                v-else
                            />行业交流
                        </div>
                        <div @click="click(4)" :class="{ hover: index1 == 4 }">
                            <img
                                style="width: 23px; height: auto"
                                src="@/assets/icon15.png"
                                v-if="index1 != 4"
                            />
                            <img
                                style="width: 23px; height: auto"
                                src="@/assets/icon16.png"
                                v-else
                            />媒体报道
                        </div>
                    </div>
                    <div class="cu_content">
                        <ul>
                            <li
                                v-for="item in listDetail"
                                :key="item.id"
                                @click="clickList(item)"
                            >
                                <div>{{ item.title }}</div>
                                <span>[{{ item.createTime }}]</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- 广告位 -->
            <div
                class="political_party"
                @click="clickList(this.contentData.advertisingSpace)"
            >
                <img
                    :src="this.contentData.advertisingSpace"
                    alt=""
                    loading="lazy"
                />
            </div>

            <!-- 荣誉列表 -->
            <div class="scroll">
                <vue-seamless-scroll
                    :class-option="classOption"
                    :data="contentData.honorList"
                    class="warp"
                >
                    <div class="row">
                        <div
                            class="s_row"
                            v-for="item in contentData.honorList"
                            :key="item.id"
                            @click="clickList2(item)"
                        >
                            <img
                                :src="
                                    item.imgUrl
                                        ? item.imgUrl + '&width=600'
                                        : ''
                                "
                                :alt="item.name"
                                loading="lazy"
                            />
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </vue-seamless-scroll>
            </div>

            <div class="list">
                <div class="c_ul">
                    <div class="cu_header2">
                        <div>党建工作</div>
                        <div @click="nav('党建工作')">
                            更多 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="cu_content">
                        <ul>
                            <li
                                v-for="item in listDetail2"
                                :key="item.id"
                                @click="clickList(item)"
                            >
                                <div>{{ item.title }}</div>
                                <span>[{{ item.createTime }}]</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="c_ul">
                    <div class="cu_header2">
                        <div>工会建设</div>
                        <div @click="nav('工会建设')">
                            更多 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="cu_content">
                        <ul>
                            <li
                                v-for="item in listDetail3"
                                :key="item.id"
                                @click="clickList(item)"
                            >
                                <div>{{ item.title }}</div>
                                <span>[{{ item.createTime }}]</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="list">
                <div class="c_ul">
                    <div class="cu_header">
                        <div @click="click2(1)" :class="{ hover: index2 == 1 }">
                            通知公告
                        </div>
                        <div @click="click2(2)" :class="{ hover: index2 == 2 }">
                            服务体系
                        </div>
                    </div>
                    <div class="cu_content">
                        <ul>
                            <li
                                v-for="item in listDetail4"
                                :key="item.id"
                                @click="clickList(item)"
                            >
                                <div>{{ item.title }}</div>
                                <span>[{{ item.createTime }}]</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="c_ul2">
                    <div class="cu_header">
                        <div>
                            <img src="@/assets/icon2.png" alt="" />
                            环卫集团宣传片
                        </div>
                    </div>
                    <div class="cu_video">
                        <div class="cuv_title">
                            <div
                                @click="playVideo(item, index)"
                                :class="{ hover: index3 == index }"
                                v-for="(item, index) in contentData.videoList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                        <video-player
                            id="video"
                            class="video-player vjs-custom-skin"
                            ref="videoPlayer"
                            :playsinline="true"
                            :options="playerOptions"
                        ></video-player>
                    </div>
                </div>
            </div>

            <div class="supervise">
                <div class="s_row">
                    <div class="sr_top">
                        <div><img src="@/assets/img7.png" alt="" /></div>
                        <div><img src="@/assets/img1.png" alt="" /></div>
                    </div>
                    <div class="sr_top">
                        <div><img src="@/assets/img2.png" alt="" /></div>
                        <div><img src="@/assets/img3.png" alt="" /></div>
                    </div>
                    <div class="sr_bottom">
                        <img src="@/assets/img.png" alt="" />
                    </div>
                </div>
                <div class="s_row">
                    <div class="c_ul">
                        <div class="cu_header2">
                            <div>失物招领</div>
                            <div @click="nav('失物招领')">
                                更多 <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <div class="cu_content">
                            <div class="cuc_top">
                                <div class="cuct_left">
                                    <img
                                        :src="shiwu[0].mainImg"
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                                <div
                                    class="cuct_right"
                                    @click="clickList(shiwu[0])"
                                >
                                    <div class="cuctr_top">
                                        {{ shiwu[0].title }}
                                    </div>
                                    <div class="cuctr_bottom">
                                        {{ shiwu[0].description }}
                                        <span>[查看详情]</span>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li
                                    v-for="item in listDetail5"
                                    :key="item.id"
                                    @click="clickList(item)"
                                >
                                    <div>{{ item.title }}</div>
                                    <span>[{{ item.createTime }}]</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 便民一站通 -->
            <div class="people">
                <div class="p_left"><img src="@/assets/img6.png" alt="" /></div>
                <div class="p_right">
                    <vue-seamless-scroll
                        :class-option="classOption"
                        :data="contentData.convenienceList"
                        class="warp"
                    >
                        <div class="row2">
                            <div
                                class="pr_row"
                                v-for="item in contentData.convenienceList"
                                :key="item.id"
                                @click="click3(item.url)"
                            >
                                <img :src="item.icon" alt="" loading="lazy" />
                                <span>{{ item.title }}</span>
                            </div>
                        </div>
                    </vue-seamless-scroll>
                </div>
            </div>

            <div class="link">
                <div class="link_left">
                    <i class="el-icon-link"></i>
                    友情链接:
                </div>
                <div class="lint_right">
                    <div class="link_content">
                        <i class="el-icon-arrow-left" @click="prev()"></i>
                        <el-carousel
                            :interval="5000"
                            arrow="always"
                            height="80px"
                            ref="img"
                        >
                            <el-carousel-item
                                v-for="(item, index) in friendlyLinkList"
                                :key="index + 1"
                            >
                                <div
                                    class="link_row"
                                    v-for="item2 in item"
                                    @click="click3(item2.url)"
                                    :key="item2.id"
                                >
                                    <img
                                        :src="item2.icon"
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <i class="el-icon-arrow-right" @click="next()"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import 'videojs-contrib-hls'; // 直播推流
import { videoPlayer } from 'vue-video-player';
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
    name: 'index',
    // 动态设置页面标题、关键字和描述等属性
    metaInfo() {
        return {
            title: this.siteInfo.title,
            meta: [
                { name: 'keywords', content: this.siteInfo.keyword },
                { name: 'description', content: this.siteInfo.description }
            ]
        };
    },
    data() {
        return {
            fileAreaHeight: 223,
            fileType: 'application/x-mpegURL', // 资源的类型
            mp4Pic: '',
            mp4Url: '',
            Info: '',
            totalVideoTime: '',
            //上一次播放时间
            initialTime: '',
            // 是否首次进入
            isFirstPlay: false,
            //剩余时长
            playtimes: '',
            topData: {},
            navigationBar: '',
            contentData: {},
            friendlyLinkList: [],
            listDetail: '',
            listDetail2: '',
            listDetail3: '',
            listDetail4: '',
            listDetail5: '',
            siteInfo: {},
            index1: 1,
            index2: 1,
            index3: 0,
            shiwu: [{ mainImg: '11', title: '111' }],
            topInformation: {},
            hotinfoList: [{}, {}, {}],
            classOption: {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 15, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 0 // 单步运动停止的时间(默认值1000ms)
            }
        };
    },
    components: {
        videoPlayer,
        vueSeamlessScroll
    },
    computed: {
        // 使用计算属性
        playerOptions() {
            const playerOptionsObj = {
                playbackRates: [1], //视频播放速度
                autoplay: false, // 如果true，浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
                language: 'zh-CN',
                // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
                fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        // type: 'video/' + this.fileType, // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
                        type: 'application/x-mpegURL',
                        src: this.mp4Url // 视频url地址
                    }
                ],
                hls: true, // 启用hls？
                poster: this.mp4Pic, // 视频封面地址
                // width: document.documentElement.clientWidth,
                height: this.fileAreaHeight, // 设置高度，fluid需要设置成flase
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true //全屏按钮
                }
            };
            return playerOptionsObj;
        }
    },

    mounted() {
        this.$axios.get('/api/business/homePageInfo/getTop').then((res) => {
            this.topInformation = res.data.data.topInformation;
            this.hotinfoList = res.data.data.hotinfoList
                ? res.data.data.hotinfoList
                : [];
            this.topData = res.data.data;
        });
        this.$axios.get('/api/business/homePageInfo/getBottom').then((res) => {
            this.siteInfo = res.data.data.siteInfo;
        });
        this.$axios.get('/api/business/homePageInfo/getContent').then((res) => {
            this.listDetail = res.data.data.tabList['环卫动态']
                ? res.data.data.tabList['环卫动态']
                : [];
            this.listDetail2 = res.data.data.tabList['党建工作']
                ? res.data.data.tabList['党建工作']
                : [];
            this.listDetail3 = res.data.data.tabList['工会建设']
                ? res.data.data.tabList['工会建设']
                : [];
            this.listDetail4 = res.data.data.tabList['通知公告']
                ? res.data.data.tabList['通知公告']
                : [];
            this.shiwu = res.data.data.tabList['失物招领']
                ? res.data.data.tabList['失物招领'].splice(0, 1)
                : this.shiwu;
            this.listDetail5 = res.data.data.tabList['失物招领']
                ? res.data.data.tabList['失物招领']
                : [];
            this.friendlyLinkList = this.group(
                res.data.data.friendlyLinkList,
                4
            );
            this.classOption.limitMoveNum = this.friendlyLinkList.length;
            this.mp4Url = res.data.data.videoList[0].url;
            this.contentData = res.data.data;
        });
    },

    methods: {
        group(array, subGroupLength) {
            let index = 0;
            let newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, (index += subGroupLength)));
            }
            return newArray;
        },
        click(e) {
            this.index1 = e;
            if (e == 1) {
                this.listDetail = this.contentData.tabList['环卫动态'];
            } else if (e == 2) {
                this.listDetail = this.contentData.tabList['领导关怀'];
            } else if (e == 3) {
                this.listDetail = this.contentData.tabList['行业交流'];
            } else if (e == 4) {
                this.listDetail = this.contentData.tabList['媒体报道'];
            }
        },
        click2(e) {
            this.index2 = e;
            if (e == 1) {
                this.listDetail4 = this.contentData.tabList['通知公告'];
            } else if (e == 2) {
                this.listDetail4 = this.contentData.tabList['服务体系'];
            }
        },
        click3(e) {
            window.open(e);
        },

        swiperImg(e) {
            if (e) {
                window.open(e);
            }
        },

        playVideo(item, e) {
            this.index3 = e;
            this.mp4Url = item.url;
        },

        clickList(e) {
            this.$router.push({
                path: `/newDetail/${e.id}`
            });
        },
        clickList2(e) {
            if (e.url) {
                window.open(e.url);
            }
        },
        nav(e) {
            let data = this.topData.navigationBar;
            for (let i in data) {
                if (data[i].name == e) {
                    this.$router.push('/newList/' + data[i].id);
                } else if (e == '失物招领' || e == '工会建设') {
                    if (data[i].children.length > 0) {
                        for (let j in data[i].children) {
                            if (data[i].children[j].name == e) {
                                this.$router.push(
                                    '/newList/' + data[i].children[j].id
                                );
                            }
                        }
                    }
                }
            }
        },
        prev() {
            this.$refs.img.prev();
        },
        next() {
            this.$refs.img.next();
        }
    }
};
</script>

<style lang="less" scoped>
.frame {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;

    .bg {
        width: 1200px;
        height: auto;
        background: white;
        overflow: hidden;
        margin: 0 auto;

        .swiper {
            width: 100%;
            height: 348px;
            margin: 0 auto;
            cursor: pointer;

            img {
                width: 100%;
                height: auto;
            }
        }

        .headlines {
            width: 98%;
            height: auto;
            margin: 17px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 7px 27px;
            box-sizing: border-box;
            background: #f6f6f6;

            .h_img {
                width: 49px;
                height: 66px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .h_right {
                width: 1070px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .ht_title {
                    width: 100%;
                    line-height: 50px;
                    color: red;
                    font-weight: bold;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }

                .el-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;
                    text-align: center;
                }

                .el-col {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
        }

        .content {
            width: 98%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .c_swiper {
                width: 497px;
                height: 290px;
                position: relative;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }

                .cs_title {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    line-height: 50px;
                    background: #27272784;
                    font-size: 12px;
                    color: white;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 10px;
                    box-sizing: border-box;
                }
            }

            .c_ul {
                width: 660px;
                height: auto;
                overflow: hidden;

                .cu_header {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;

                    div {
                        flex: 1;
                        background: #ecf3f9;
                        font-size: 14px;
                        color: black;
                        text-align: center;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 10px;
                        box-sizing: border-box;
                        cursor: pointer;

                        img {
                            width: 30px;
                            height: 25px;
                            margin-right: 10px;
                        }
                    }
                }

                .hover {
                    background: #016bd5 !important;
                    color: white !important;
                }

                .cu_content {
                    width: 100%;
                    min-height: 240px;

                    ul {
                        display: block;
                        width: 100%;
                        list-style-type: disc;

                        li {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 14px;
                            color: #272727;
                            line-height: 40px;
                            position: relative;
                            cursor: pointer;

                            :hover {
                                color: #016bd5be !important;
                            }

                            div {
                                width: 70%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-left: 3%;
                            }

                            span {
                                color: #a5a5a5;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: 18.5px;
                                left: 0;
                                width: 5px;
                                height: 5px;
                                border-radius: 5px;
                                background: #a5a5a5;
                            }
                        }
                    }
                }
            }
        }

        .political_party {
            width: 98%;
            margin: 10px auto 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        .scroll {
            width: 98%;
            margin: 10px auto;
            background: #f6f6f6;
            padding: 8px 5px;
            // box-sizing: border-box;
            overflow: hidden;

            .warp {
                // overflow: hidden;
                // height: 136px;
                // width: 100%;

                .row {
                    // overflow: hidden;
                    height: 136px;
                    display: flex;
                    .s_row {
                        width: 200px;
                        margin-right: 10px;
                        cursor: pointer;
                        // overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        float: left;
                        img {
                            width: 200px;
                            height: 112.5px;
                            display: block;
                        }

                        span {
                            display: block;
                            width: 200px;
                            text-align: center;
                            color: #6f6f6f;
                            font-size: 12px;
                            margin-top: 6px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-right: 6px;
                        }
                    }
                }
            }
        }

        .list {
            width: 98%;
            min-height: 300px;
            margin: 10px auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 4px 5px;
            box-sizing: border-box;
            overflow: hidden;

            .c_ul {
                width: 49%;
                height: auto;
                overflow: hidden;
                background: white;
                overflow: hidden;

                .cu_header {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    background: #ecf3f9;
                    text-align: center;

                    div {
                        width: 150px;
                        background: #ecf3f9;
                        font-size: 14px;
                        color: black;
                        text-align: center;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 10px;
                        box-sizing: border-box;
                        cursor: pointer;

                        img {
                            width: 30px;
                            height: 25px;
                            margin-right: 10px;
                        }
                    }
                }

                .hover {
                    background: #016bd5 !important;
                    color: white !important;
                }

                .cu_header2 {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #f8f8f8;

                    div {
                        &:nth-child(1) {
                            flex: 1;
                            font-size: 16px;
                            color: #0a6ed4;
                            text-align: center;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 10px 10px;
                            box-sizing: border-box;
                            position: relative;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 90px;
                                height: 5px;
                                background: #0a6ed4;
                            }
                        }

                        &:nth-child(2) {
                            font-size: 14px;
                            color: #9a9a9a;
                            text-align: right;
                            cursor: pointer;
                        }
                    }
                }

                .cu_content {
                    width: 100%;

                    ul {
                        display: block;
                        width: 100%;
                        list-style-type: disc;

                        li {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 14px;
                            color: #272727;
                            line-height: 40px;
                            position: relative;
                            cursor: pointer;

                            :hover {
                                color: #016bd5be !important;
                            }

                            div {
                                width: 70%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-left: 3%;
                            }

                            span {
                                color: #a5a5a5;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: 17px;
                                left: 0;
                                width: 5px;
                                height: 5px;
                                border-radius: 5px;
                                background: #016bd5;
                            }
                        }
                    }
                }
            }

            .c_ul2 {
                width: 49%;
                height: auto;
                overflow: hidden;
                background: white;
                overflow: hidden;
                border: 1px solid #ecf3f9;

                .cu_header {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    background: #ecf3f9;

                    div {
                        background: #ecf3f9;
                        font-size: 14px;
                        color: black;
                        text-align: center;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 10px;
                        box-sizing: border-box;

                        img {
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                        }
                    }
                }

                .hover {
                    background: #016bd5 !important;
                    color: white !important;

                    div {
                        color: white !important;
                    }
                }

                .cu_video {
                    width: 98%;
                    height: 262px;
                    margin: 8px auto;

                    .cuv_title {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        background: #f1f1f1;
                        margin: 8px 0 0;

                        div {
                            width: 50%;
                            color: #b7b7b7;
                            text-align: center;
                            line-height: 40px;
                        }
                    }

                    .video-player {
                        width: 100%;
                        height: 223px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .supervise {
            width: 98%;
            margin: 10px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 4px 5px;
            box-sizing: border-box;
            overflow: hidden;

            .s_row {
                width: 49%;
                height: auto;
                overflow: hidden;
                background: white;
                overflow: hidden;

                .sr_top {
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    img {
                        width: 267px;
                        height: auto;
                    }
                }

                .sr_bottom {
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .c_ul {
                    width: 100%;
                    height: auto;
                    overflow: hidden;
                    background: white;
                    overflow: hidden;

                    .cu_header2 {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #f8f8f8;

                        div {
                            &:nth-child(1) {
                                flex: 1;
                                font-size: 16px;
                                color: #0a6ed4;
                                text-align: center;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 10px 10px;
                                box-sizing: border-box;
                                position: relative;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    width: 90px;
                                    height: 5px;
                                    background: #0a6ed4;
                                }
                            }

                            &:nth-child(2) {
                                font-size: 14px;
                                color: #9a9a9a;
                                text-align: right;
                                cursor: pointer;
                            }
                        }
                    }

                    .cu_content {
                        width: 100%;
                        min-height: 285px;

                        .cuc_top {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            background: #ecf3f9;
                            align-items: center;
                            padding: 5px 10px;
                            box-sizing: border-box;
                            margin-top: 5px;
                            cursor: pointer;

                            .cuct_left {
                                width: 134px;
                                height: auto;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }

                            .cuct_right {
                                width: 70%;
                                margin-left: 2%;

                                .cuctr_top {
                                    font-size: 16px;
                                    line-height: 26px;
                                    color: #272727;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                .cuctr_bottom {
                                    font-size: 12px;
                                    line-height: 26px;
                                    height: 52px;
                                    color: #92979a;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;

                                    span {
                                        display: inline-block;
                                        color: #016bd5;
                                    }
                                }
                            }
                        }

                        ul {
                            display: block;
                            width: 100%;
                            list-style-type: disc;

                            li {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                font-size: 14px;
                                color: #272727;
                                line-height: 40px;
                                position: relative;
                                cursor: pointer;

                                :hover {
                                    color: #016bd5be !important;
                                }

                                div {
                                    width: 70%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    margin-left: 3%;
                                }

                                span {
                                    color: #a5a5a5;
                                }

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 17px;
                                    left: 0;
                                    width: 5px;
                                    height: 5px;
                                    border-radius: 5px;
                                    background: #016bd5;
                                }
                            }
                        }
                    }
                }
            }
        }

        .people {
            width: 98%;
            height: 122px;
            margin: 10px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background: white;
            border: 1px solid #eaf4ff;
            border-radius: 5px;
            padding: 8px 5px;
            box-sizing: border-box;
            overflow: hidden;

            .p_left {
                width: 108px;
                height: 102px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .p_right {
                width: 90%;
                overflow: hidden;
                pointer-events: auto;

                .row2 {
                    height: 102px;
                    display: flex;
                    flex-direction: row;
                }

                .pr_row {
                    width: 110px;
                    height: 102px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    margin-left: 10px;
                    border-radius: 5px;
                    background: #f3f9fd;
                    cursor: pointer;
                    float: left;
                    img {
                        display: block;
                        height: 35px;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        color: #6f6f6f;
                        font-size: 12px;
                        margin-top: 6px;
                    }
                }
            }
        }

        .link {
            width: 98%;
            height: 82px;
            margin: 5px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background: white;

            .link_left {
                width: 140px;
                height: 80px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #92979a;
                margin-right: 10px;

                i {
                    display: inline-block;
                    margin-right: 4%;
                }
            }

            .lint_right {
                width: 1060px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .link_content {
                    width: 930px;
                    height: 80px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    i {
                        font-size: 30px;
                        cursor: pointer;
                    }

                    /deep/.el-carousel {
                        width: 100%;
                    }

                    /deep/.el-carousel__item {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
                    }

                    /deep/.el-carousel__arrow {
                        display: none !important;
                    }

                    .link_row {
                        width: 240px;
                        overflow: hidden;
                        margin-right: 10px;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: auto;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}
</style>
