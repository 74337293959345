import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
Vue.config.productionTip = false;

// // main.js中
// import VideoPlayer from 'vue-video-player/src';
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
// 引入Element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueMeta from 'vue-meta'

// import 'vue-dplayer/dist/vue-dplayer.css';
// import dPlayer from 'vue-dplayer';
// // 全局注册Vue DPlayer组件
// Vue.component('dPlayer', dPlayer);

Vue.prototype.$axios = axios
Vue.use(VueMeta)
Vue.use(ElementUI);
// Vue.use(VideoPlayer)

new Vue({
  router,
  render: h => h(App),
  mounted() {
    // 这句是重点， 'render-event' 需要和 vue.config.js里的renderAfterDocumentEvent值一致
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount('#app')
