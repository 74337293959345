import Vue from 'vue'
import Router from 'vue-router'
import index from '@/pages/index'
import newDetail from '@/pages/newDetail'
import newList from '@/pages/newList'
Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: 'index',
            component: index
        },
        {
            path: '/newList/:cid',
            name: 'newList',
            component: newList
        },
        {
            path: '/newDetail/:id',
            name: 'newDetail',
            component: newDetail
        },
    ]
})