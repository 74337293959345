<template>
    <div class="frame">
        <div class="bg"></div>
        <div class="content">
            <div class="c_left">
                <div class="cl_top">
                    <span>{{ nav.name }}</span>
                    <span></span>
                </div>
                <div class="cl_bottom">
                    <div class="block" v-for="item in nav.children" :key="item.id + 1" @click="click(item)">{{ item.name
                    }}
                    </div>
                </div>
            </div>
            <div class="c_right">
                <div class="cr_title">
                    <span>您当前的位置：</span>
                    <el-breadcrumb separator=">">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ nav.name }}</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="cname != ''">{{ cname }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="cr_content">
                    <div class="crc_row" v-for="item in data" :key="item.id" @click="click2(item.id)">
                        <div class="crcr_left"><img :src="item.mainImg||'http://static.lyhjwsjt.com.cn/repository/image/default.jpg'" ></div>
                        <div class="crcr_right">
                            <div class="cr_top">{{ item.title }}</div>
                            <div class="cr_on">{{ item.description }}</div>
                            <div class="cr_bottom">
                                <div class="crb_left">发布时间：{{ item.createTime }}</div>
                                <div class="crb_right">了解详情+</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fbottom">
                    <el-pagination :current-page="page.pageNum" @current-change="getList" :page-size="page.pageSize"
                        background layout="prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: null,
            page: {
                pageNum: 1,
                pageSize: 10,
                categoryId: ''
            },
            total: 0,
            nav: '',
            cid: '',
            cname: '',
            seodata: {},
            defaultImg: 'this.src = "' + require("../assets/bg7.png") + '"', //默认图片的地址
        }
    },
    metaInfo() {
        return {
            title: this.seodata.title,
            meta: [
                { name: "keywords", content: this.seodata.keywords },
                { name: "description", content: this.seodata.description },
            ]
        }
    },
    watch: {
        $route: {
            handler(nel, old) {
                this.cid = nel.params.cid
                this.page.categoryId = nel.params.cid
                this.getList()
                this.getMenu()
                this.getSeo()
            },
            immediate: true,
        },
        nav(nel, old) {
            for (let i in nel.children) {
                if (nel.children[i].id == this.cid) {
                    console.log(nel.children[i], 2)
                    this.cname = nel.children[i].name
                }
            }
        }
    },
    mounted() {
        this.page.categoryId = this.$route.params.cid
        this.cid = this.$route.params.cid
        this.cname = ''
        this.getList()
        this.getMenu()
        this.getSeo()
    },

    methods: {

        setDefaultImage(event) {
            event.target.src = 'https://static.lyhjwsjt.com.cn/repository/image/default.jpg';
        },
        getSeo() {
            this.$axios.get(`/api/business/homePageInfo/getPageSEO/${this.cid}`).then(res => {
                this.seodata = res.data.data
            })
        },
        getList(e) {
            this.page.pageNum = e
            this.$axios.get('/api/business/homePageInfo/page', { params: { ...this.page } }).then(res => {
                this.data = res.data.data.list
                this.total = res.data.data.total
            })
        },

        getMenu() {
            this.$axios.get('/api/business/homePageInfo/getTop').then(res => {
                let data = res.data.data.navigationBar
                for (let i in data) {
                    if (data[i].id == this.cid) {
                        this.nav = data[i]
                        return
                    } else {
                        for (let j in data[i].children) {
                            if (data[i].children[j].id == this.cid) {
                                this.nav = data[i]
                                return
                            }
                        }
                    }
                }
            })
        },
        click(e) {
            if (e.url) {
                if (e.openType == 1) {
                    window.open(e.url)
                } else {
                    window.location.replace(e.url)
                }
            } else {
                this.cname = ''
                this.cid = e.id
                this.page = {
                    pageNum: 1,
                    pageSize: 10,
                    categoryId: e.id
                }
                for (let i in this.nav.children) {
                    if (this.nav.children[i].id == e.id) {
                        this.cname = this.nav.children[i].name
                    }
                }
                this.getList()
            }
        },

        click2(e) {
            this.$router.push({
                path: `/newDetail/${e}`
            })
        }
    }
}
</script>
<style lang="less" scoped>
.frame {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 424px;
        background: url('@/assets/bg1.png') no-repeat;
        background-size: 100% 100%;
    }

    .content {
        width: 1200px;
        height: auto;
        margin: -240px auto 0;
        background: white;
        display: flex;
        flex-direction: row;

        .c_left {
            width: 217px;
            height: auto;
            background: white;
            margin-top: -50px;

            .cl_top {
                width: 217px;
                height: 123px;
                background: url('@/assets/icon.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;

                span {
                    display: inline-block;
                    margin-left: 26px;

                    &:nth-child(1) {
                        font-size: 23px;
                        color: white;
                        margin-top: 20px;

                    }

                    &:nth-child(2) {
                        font-size: 14px;
                        color: #659DE4;
                        margin-top: 5px;
                    }
                }
            }

            .cl_bottom {
                width: 100%;
                display: flex;
                height: auto;
                flex-direction: column;
                justify-content: center;
                margin-top: 5px;
                border-right: 1px solid #F5F5F5;

                :hover {
                    background: #3687f1 !important;
                    color: white !important;
                }

                div {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    cursor: pointer;
                    border-bottom: 1px solid #F5F5F5;

                    &:last-child {
                        border: none !important;
                    }
                }
            }
        }

        .c_right {
            width: 933px;
            height: auto;
            display: flex;
            flex-direction: column;
            margin-left: 40px;
            padding: 0 30px;
            box-sizing: border-box;

            .cr_title {
                width: 100%;
                height: 60px;
                line-height: 60px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 20px;
                border-bottom: 1px solid #DDDDDD;
                padding-right: 20px;
                box-sizing: border-box;
                font-size: 14px;
                color: #606266;

            }

            .cr_content {
                margin: 0px auto 0;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .crc_row {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 30px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #DDDDDD;

                    .crcr_left {
                        width: 310px;
                        height: auto;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .crcr_right {
                        width: 518px;
                        margin-left: 20px;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        :hover {
                            color: #016bd5be !important;
                        }


                        .cr_top {
                            font-size: 18px;
                            line-height: 40px;
                            color: #333333;
                            cursor: pointer;


                        }

                        .cr_on {
                            font-size: 14px;
                            height: 100px;
                            line-height: 25px;
                            color: #666666;
                            display: -webkit-box; //特别显示模式，将对象作为弹性伸缩盒子模型显示 
                            -webkit-box-orient: vertical; //盒子中内容竖直排列
                            -webkit-line-clamp: 4; //行数
                            overflow: hidden;
                            text-overflow: ellipsis; //文本溢出部分用省略号表示
                            margin: 10px 0 30px;
                        }

                        .cr_bottom {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .crb_left {
                                font-size: 12px;
                                color: #888888;
                            }

                            .crb_right {
                                font-size: 12px;
                                color: #016BD5;
                                text-align: right;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .fbottom {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin: 20px 0 40px;
            }
        }
    }
}
</style>
