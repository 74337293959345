<template>
    <div id="app" class="app2">
        <HeaderNav
            :navData="topData.navigationBar"
            :siteInfo="siteInfo.siteInfo"
        ></HeaderNav>
        <router-view></router-view>
        <Bottom :siteInfo="siteInfo"></Bottom>
    </div>
</template>

<script>
import HeaderNav from './components/HeaderNav.vue';
import Bottom from './components/bottom.vue';
export default {
    name: 'App',
    components: {
        Bottom,
        HeaderNav
    },
    data() {
        return {
            topData: {},
            siteInfo: {}
        };
    },
    mounted() {
        this.$axios.get('/api/business/homePageInfo/getTop').then((res) => {
            this.topData = res.data.data;
        });
        this.$axios.get('/api/business/homePageInfo/getBottom').then((res) => {
            this.siteInfo = res.data.data;
        });
    }
};
</script>

<style lang="less">
@import '../common.less';
.app2 {
    width: 100%;
    background: url('../src/assets/bg6new.png?width=600') no-repeat;
    position: absolute;
}
</style>
