<template>
    <div class="content">
        <div class="header">
            <div class="logo" @click="logo()">
                <img :src="topLogo" loading="lazy" />
            </div>
            <div class="mine">
                <el-input v-model="input" placeholder="请输入内容">
                    <i
                        slot="suffix"
                        class="el-input__icon el-icon-search cv"
                        style="width: 30px; color: #016bd5"
                    ></i>
                </el-input>
            </div>
        </div>
        <div class="nav">
            <el-menu
                :default-active="activeIndex"
                background-color="#016BD5"
                active-text-color="#FFF5B3"
                text-color="white"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
            >
                <div v-for="(item, index) in navData2" :key="item.id + 1">
                    <el-submenu
                        :index="`${index}`"
                        v-if="item.children.length > 0"
                    >
                        <template slot="title">{{ item.name }}</template>
                        <el-menu-item
                            :index="`${index}-${index2}`"
                            v-for="(item2, index2) in item.children"
                            :key="item2.id + 1"
                            >{{ item2.name }}</el-menu-item
                        >
                    </el-submenu>
                    <el-menu-item :index="`${index}`" v-else>{{
                        item.name
                    }}</el-menu-item>
                </div>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderNav',
    data() {
        return {
            activeIndex: '',
            input: '',
            topBackdrop: '',
            topLogo: '',
            navData2: ''
        };
    },
    watch: {
        siteInfo(nel, old) {
            this.topBackdrop = nel.topBackdrop;
            this.topLogo = nel.topLogo;
        },
        navData(nel, old) {
            this.navData2 = nel;
        }
    },
    props: {
        navData: Array,
        siteInfo: Object
    },

    methods: {
        handleSelect(key, e) {
            let id = this.$route.params.cid ? this.$route.params.cid : '';
            if (e.length > 1) {
                let cid = e[1].split('-');
                if (id == this.navData2[cid[0]].children[parseInt(cid[1])].id) {
                    this.$router.go(0);
                    return;
                }
                if (
                    this.navData2[parseInt(cid[0])].children[parseInt(cid[1])]
                        .url
                ) {
                    if (
                        this.navData2[parseInt(cid[0])].children[
                            parseInt(cid[1])
                        ].openType == 1
                    ) {
                        window.open(
                            this.navData2[parseInt(cid[0])].children[
                                parseInt(cid[1])
                            ].url
                        );
                    } else {
                        window.location.replace(
                            this.navData2[parseInt(cid[0])].children[
                                parseInt(cid[1])
                            ].url
                        );
                    }
                } else {
                    this.$router.push(
                        '/newList/' +
                            this.navData2[parseInt(cid[0])].children[
                                parseInt(cid[1])
                            ].id
                    );
                }
            } else {
                if (this.navData2[parseInt(e[0])].url) {
                    if (this.navData2[parseInt(e[0])].openType == 1) {
                        window.open(this.navData2[parseInt(e[0])].url);
                    } else {
                        window.location.replace(
                            this.navData2[parseInt(e[0])].url
                        );
                    }
                } else {
                    this.$router.push(
                        '/newList/' + this.navData2[parseInt(e[0])].id
                    );
                    this.$router.go(0);
                }
            }
        },

        logo() {
            this.$router.push('/');
        }
    }
};
</script>
<style lang="less" scoped>
.content {
    width: 100%;
    height: auto;
    display: block;
    // background: white;

    /deep/.el-input__inner {
        border: 1px solid #016bd5 !important;
    }

    /deep/.el-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        div {
            flex: 1 !important;
        }
    }

    .cv {
        cursor: pointer;
    }

    /deep/.el-submenu {
        border-right: 1px solid #0054ab !important;
        text-align: center !important;
    }

    /deep/.el-menu-item {
        text-align: center !important;
        height: 50px !important;
        line-height: 50px !important;
        border-right: 1px solid #0054ab !important;
        flex: 1 !important;
    }

    /deep/.el-submenu__title {
        height: 50px !important;
        line-height: 50px !important;
        border: none !important;
        flex: 1 !important;
    }

    .header {
        width: 100%;
        height: 167px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 353px;
        box-sizing: border-box;
        // background: url('@/assets/img8.png') no-repeat;
        background-size: 100% 100%;
        justify-content: space-between;

        .logo {
            width: 502px;
            height: 100px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }
        }

        .mine {
            width: 377px;
            height: 38px;
            line-height: 38px;
            margin-top: 20px;
        }
    }

    .nav {
        width: 100%;
        height: 50px;
        padding: 0 360px;
        box-sizing: border-box;
        background: #016bd5;

        .el-menu-demo {
            height: 50px;
            line-height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }
}
</style>
