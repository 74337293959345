<template>
    <div class="content">
        <div class="bottom">
            <div class="b_left">
                <img
                    class="img"
                    :src="siteInfo2.bottomLogo"
                    alt=""
                    loading="lazy"
                />
                <span>{{ siteInfo2.websiteRecordInfo }}</span>
                <span
                    >{{ siteInfo2.copyrightInfo }}
                    <img src="@/assets/bicon2.png" alt="" />
                    <a :href="siteInfo2.publicSecurityRecordUrl">{{
                        siteInfo2.publicSecurityRecordInfo
                    }}</a>
                </span>
            </div>
            <div class="b_on">
                <div class="bo_left">
                    <img src="@/assets/bicon1.png" alt="" />
                </div>
                <div class="bo_right">
                    <span>联系我们</span>
                    <span>电话：{{ companyInfo.phone }}</span>
                    <span>邮箱：{{ companyInfo.email }}</span>
                    <span>邮编：{{ companyInfo.postcode }}</span>
                    <span>地址：{{ companyInfo.address }}</span>
                </div>
            </div>
            <div class="b_right">
                <img :src="companyInfo.wxQrCode" alt="" loading="lazy" />
                <img
                    :src="companyInfo.smallProgramCode"
                    alt=""
                    loading="lazy"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bottom',
    data() {
        return {
            data: '',
            siteInfo2: {},
            companyInfo: {}
        };
    },
    watch: {
        siteInfo(nel, old) {
            this.companyInfo = nel.companyInfo;
            this.siteInfo2 = nel.siteInfo;
        }
    },
    props: {
        siteInfo: Object
    }
};
</script>
<style lang="less" scoped>
.content {
    width: 100%;
    display: block;
    background: #154a7f;

    .bottom {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;

        .b_left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            a {
                color: white;
            }

            .img {
                width: 317px;
                height: auto;
                vertical-align: middle;
                margin-bottom: 10px;
            }

            span {
                width: 100%;
                text-align: left;
                line-height: 30px;
                color: white;
                font-size: 12px;

                img {
                    width: 19px;
                    height: auto;
                    vertical-align: middle;
                }

                span {
                    display: inline-block !important;
                }
            }
        }

        .b_on {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .bo_left {
                width: 127px;
                margin-right: 20px;
                margin-top: 30px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .bo_right {
                display: flex;
                flex-direction: column;

                span {
                    width: 100%;
                    text-align: left;
                    line-height: 20px;
                    color: white;
                    font-size: 12px;

                    &:nth-child(1) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                }
            }
        }

        .b_right {
            width: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            img {
                width: 110px;
                height: 110px;
            }
        }
    }
}
</style>
